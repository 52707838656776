import React from 'react';
import { FaGithubSquare, FaLinkedin, FaWhatsappSquare } from "react-icons/fa";
import myImg from '../../Assets/img/FrontEnd-Developer.avif';

const Home = () => {
    return (
        <div>
            <div className='w-11/12 max-w-screen-lg kib_box_shadow my-8 rounded-3xl mx-auto md:mx-10 py-8 lg:py-auto  md:flex justify-evenly items-start '>

                <div>
                    <img className="w-[200px] h-auto md:mb-0  mx-auto md:mx-5 kib_box_shadow rounded-full hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 grayscale hover:grayscale-0" src={myImg} alt='myImg' />
                    <div className=" flex  justify-center py-8 gap-5 ">
                        <a target='_blank' rel="noreferrer" aria-label="My Linkedin Profile Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://github.com/kibria-khandaker" >
                            <FaGithubSquare />
                            <span class="tooltiptext2 text-sm "> Github </span>
                        </a>
                        <a target='_blank' rel="noreferrer" aria-label="My Twitter Profile Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://www.linkedin.com/in/kibria-khandaker/" >
                            <FaLinkedin />
                            <span class="tooltiptext2 text-sm "> Linkedin </span>
                        </a>
                        <a target='_blank' rel="noreferrer" aria-label="My WhatsApp Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://wa.me/+8801913595268" >
                            <FaWhatsappSquare />
                            <span class="tooltiptext2 text-sm "> WhatsApp </span>
                        </a>
                    </div>
                </div>

                <div className=' mx-5 md:mx-5  flex flex-col text-center '>
                    <div className='kib_box_shadow pt-5 pb-7 px-6 md:px-8 rounded-lg  text-[#7a8190]'>
                        <h2 className=' font-bold md:text-2xl pb-4'>  Hey! I'm Golam Kibria </h2>
                        <p className='md:font-bold text-lg my-4 text-[#8a92a5] underline underline-offset-8 decoration-0 decoration-dotted  hover:decoration-2 ' > Full-stack & Frontend developer </p>
                        {/* <p className='md:font-semibold mb-6 text-[#8a92a5]'> Front End || Back End || WordPress </p> */}
                        <a target='_blank' rel="noreferrer"
                            aria-label="My Resume Download Link Here"
                            className='kib_inside_effect  rounded my-5 inline-block py-4 px-3' href="https://drive.google.com/file/d/13-I3r6TNnNl02AnzF2bOlYZspa5saxRP/view?usp=sharing" > Download Resume </a>
                    </div>
                    <p className='text-[#7a8190] my-12 text-sm'> <span className='animate-pulse text-slate-900'>
                        I am comfortable with these  skills </span>:<br></br>
                        Next.js,
                        Reactjs,
                        Javascript,
                        Typescript,
                        PHP,                        
                        Redux,                        
                        Laravel,
                        WordPress,
                        Tailwind css,<br></br>
                        Bootstrap css,
                        Photoshop,
                        Figma,
                        Node.js,
                        Express,
                        MongoDB,
                        Mongoosejs,
                        MySQL,
                        Prisma,
                        PostgreSQL,
                        
                        </p>
                </div>

            </div>
        </div>
    );
};

export default Home;