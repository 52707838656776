import React from 'react';

const MyProjectDetailsModal = ({ modalProjectData }) => {
    console.log('projects', modalProjectData);
    return (
        <>
            <input type="checkbox"  aria-label="product Details Modal" id="productDetailsModal" className="modal-toggle" />
            {/* border-4 border-red-600  */}
            <div className="modal">
                <div className="modal-box lg:w-8/12 w-12/12 max-w-4xl rounded-none shadow-2xl shadow-white  text-center">

                    <label htmlFor="productDetailsModal" className="kib_inside_effect text-[#fd4475]  px-4 py-2 rounded-full absolute right-5 top-5 flex justify-center items-center">✕</label>

                    <h3 className="font-semibold text-lg mt-5 mb-5 text-[#8a92a5] "> {modalProjectData?.name} </h3>
                    {/* =======================  */}
                    
                    <div className='mb-10  text-[#8a92a5]'><p>Used Technology:</p><p>{modalProjectData?.technology}</p></div>
                    
                    <p className='my-10 px-5 text-[#98a0af]'>{modalProjectData?.shortText}</p>

                    

                    <img className='kib_box_shadow w-3/5 mx-auto ' src={modalProjectData?.img1} alt="Project Images" />

                    <a target='_blank' rel="noreferrer"
                    aria-label="Project Live Website link"
                      className='kib_inside_effect text-[#8a92a5] rounded-md py-3 px-3  mt-10 mb-24 inline-block' href={modalProjectData?.pUrl} > Check Live Website </a>

                    {/* =======================  */}


                    <div className='grid grid-cols-2 gap-10'>
                        {
                            modalProjectData?.allImg.map(arryImg => (
                                <img className='kib_box_shadow' src={arryImg} alt="" />
                                ))
                        }
                    </div>
                    {/* =======================  */}
                    {/* close btn  */}
                    <div className="modal-action">
                        <label htmlFor="productDetailsModal" className="kib_inside_effect text-[#fd4475]   px-4 py-2 rounded-md my-10 mr-4">close</label>
                    </div>

                </div>
            </div>
        </>
    );
};

export default MyProjectDetailsModal;