/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import useMyToolsData from '../../hooks/useMyToolsData';

const MyTools = () => {
    const [myTools, setMyTools] = useMyToolsData();

    return (
        <div className='text-[#8a92a5]'>
            <h2 className=' text-center text-3xl font-bold mt-20 mb-5 underline underline-offset-8 decoration-0 decoration-dotted  hover:decoration-2 '> Discussion with me </h2>
            <div className='w-full capitalize p-5 grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-10 place-items-center'>
                {
                    myTools.map(myTool => (
                        <a target="_blank" href={myTool.appUrl} className='group'>
                            <div key={myTool._id} className=' rounded-md p-4 pl-3  flex justify-center items-center max-w-[200px] gap-3 kib_inside_effect'>
                                <img className=' opacity-50 group-hover:opacity-100 w-6 h-6' src={myTool.appIcon} alt="project_Image" />
                                {myTool.appTitle}
                            </div>
                        </a>
                    ))
                }
            </div>
        </div>
    );
};

export default MyTools;