import React, { useState, useEffect } from "react";

function useGitHubCommits(repo, sliceStart, sliceEnd) {
    const [commits, setCommits] = useState([]);
    const accessToken = "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";
    useEffect(() => {
        const url = `https://api.github.com/repos/${repo}/commits`;
        const config = {
            headers: {
                Authorization: `token ${accessToken}`,
            },
        };
        fetch(url, config)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                // setCommits(data.slice(0, 5));
                setCommits(data.slice(sliceStart, sliceEnd));
            })
            .catch((error) => {
                console.error(error);
            });
    }, [repo, sliceStart, sliceEnd, accessToken]);
    return commits;
}

function CommitList({ repo, sliceStart, sliceEnd }) {
    const commits = useGitHubCommits(repo, sliceStart, sliceEnd);
    return (
        <div className='text-xs italic font-thin'>
            {commits.map((commit) => (
                <p key={commit.sha} >
                    <b> {new Date(commit.commit.author.date).toLocaleString('en-GB', { timeZone: 'UTC' })} : </b>
                    {commit.commit.message}
                </p>
            ))}
        </div>
    );
}

function LastCommitDate({ repo, sliceStart, sliceEnd }) {
    const commits = useGitHubCommits(repo, sliceStart, sliceEnd);

    // Get the date of the last commit (most recent commit)
    const lastCommitDate =
        commits.length > 0 ? new Date(commits[0].commit.author.date).toLocaleDateString() : "No commits";

    return (          
            <p className="text-left "> <span className=" text-base">Last Commit on github:</span> {lastCommitDate}</p>
       
    );
}



export { CommitList, LastCommitDate };