import React from 'react';
import { FaGithubSquare, FaLinkedin, FaWhatsappSquare } from "react-icons/fa";
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div className='kib_box_shadow w-12/12  md:w-8/12 max-w-screen-lg mx-5  md:mx-10 mt-9 rounded-lg py-10 px-5 md:px-20 text-[#787f90]'>
            <h2 className=' text-center text-3xl mb-10 font-bold  underline underline-offset-8 decoration-0 decoration-dotted text-[#8a92a5] hover:decoration-2 '> About me </h2>

            <div className='text-left flex flex-col gap-5 '>
                <p> My name is Golam kibria, <br />
                    As a dedicated full stack developer, I'm fully committed to utilizing my
                    strong technical skills, genuine enthusiasm for coding, and my proven
                    ability to quickly adapt to new situations. I am eager to apply these
                    strengths in a dynamic position where I can have a significant impact,
                    continuously develop my abilities, and make a meaningful contribution to
                    my team's success. </p>


                <p>
                    <b> i am comfortable with these  skills : </b>
                    Javascript,
                    Typescript,
                    PHP,
                    Reactjs,
                    Redux,
                    Next.js,
                    Laravel,
                    WordPress,
                    Tailwind css,
                    Bootstrap css,
                    Photoshop,
                    Figma ,
                    Node.js,
                    Express,
                    MongoDB,
                    Mongoosejs,
                    MySQL,
                    Prisma,
                    PostgreSQL,
                </p>

                <div className=" flex  justify-center pt-8 pb-4 gap-5 ">
                    <a target='_blank' rel="noreferrer" aria-label="My Linkedin Profile Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://github.com/kibria-khandaker" >
                        <FaGithubSquare />
                        <span class="tooltiptext2 text-sm "> Github </span>
                    </a>
                    <a target='_blank' rel="noreferrer" aria-label="My Twitter Profile Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://www.linkedin.com/in/kibria-khandaker/" >
                        <FaLinkedin />
                        <span class="tooltiptext2 text-sm "> Linkedin </span>
                    </a>
                    <a target='_blank' rel="noreferrer" aria-label="My WhatsApp Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://wa.me/+8801913595268" >
                        <FaWhatsappSquare />
                        <span class="tooltiptext2 text-sm "> WhatsApp </span>
                    </a>
                </div>

                <Link
                    class='text-xs italic font-thin underline'
                    to='/UpdateHistory'
                >
                    Check Content Update History
                </Link>

            </div>
        </div>
    );
};

export default About;