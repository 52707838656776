
import React from 'react';
import { FaGithubSquare, FaLinkedin, FaWhatsappSquare } from "react-icons/fa";
import { CommitList, LastCommitDate } from '../Common/GitHubCommitsShow';
// import CommitList from '../Common/GitHubCommitsShow';


const UpdateHistory = () => {
    return (
        <div className='kib_box_shadow w-12/12  md:w-8/12 max-w-screen-lg mx-5  md:mx-10 mt-9 rounded-lg py-10 px-5 md:px-20 text-[#787f90]'>
            <h2 className=' text-center text-xl font-semibold'> Last update & commit history for this project </h2>

            <p className='text-center text-xs mb-10'>DD/MM/YYYY</p>
            <div className='text-left flex flex-col gap-5 '>

                {/* <CommitList repo="kibria-khandaker/my-portfolio" /> */}
                <LastCommitDate repo="kibria-khandaker/my-portfolio" sliceStart={0} sliceEnd={1} />

                <p className=' text-xs   font-thin '>Commit history</p>
                <CommitList repo="kibria-khandaker/my-portfolio" sliceStart={0} sliceEnd={5} />

                <div className=" flex  justify-center pt-8 gap-5 ">
                    {/* <a target='_blank' rel="noreferrer" className='kib_inside_effect text-[#969eb1] rounded-full py-3 px-3' href="https://www.linkedin.com/in/kibria-khandaker/" ><FaGithubSquare /></a>
                    <a target='_blank' rel="noreferrer" className='kib_inside_effect text-[#969eb1] rounded-full py-3 px-3' href="https://github.com/kibria-khandaker" ><FaLinkedin /></a>
                    
                    <a target='_blank' rel="noreferrer" className='kib_inside_effect text-[#969eb1] rounded-full py-3 px-3' href="https://wa.me/+8801913595268" ><FaWhatsappSquare /></a> */}

                    <a target='_blank' rel="noreferrer" aria-label="My Linkedin Profile Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://github.com/kibria-khandaker" >
                        <FaGithubSquare />
                        <span class="tooltiptext2 text-sm "> Github </span>
                    </a>
                    <a target='_blank' rel="noreferrer" aria-label="My Twitter Profile Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://www.linkedin.com/in/kibria-khandaker/" >
                        <FaLinkedin />
                        <span class="tooltiptext2 text-sm "> Linkedin </span>
                    </a>
                    <a target='_blank' rel="noreferrer" aria-label="My WhatsApp Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://wa.me/+8801913595268" >
                        <FaWhatsappSquare />
                        <span class="tooltiptext2 text-sm "> WhatsApp </span>
                    </a>

                </div>

            </div>
        </div>
    );
};

export default UpdateHistory;